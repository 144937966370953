<template>
    <ion-icon :icon="getIcon(type)"></ion-icon>
</template>


<script>
  import      { 
    codeSharp, skullSharp, 
    pencilSharp, trash,
    newspaper, openOutline, bugOutline, flask, mic, 
    warningOutline, gitMergeOutline, saveSharp,
    addCircleOutline, linkOutline, mailOutline, refreshCircleOutline, pricetagsOutline, helpCircleOutline, chatboxEllipsesOutline,
    logOutOutline, search, arrowUpCircleOutline, arrowDownCircleOutline, closeCircleOutline
  } from 'ionicons/icons';
  const icons = { codeSharp, skullSharp, 
    pencilSharp, trash,
    newspaper, openOutline, bugOutline, flask, mic, 
    warningOutline, gitMergeOutline, saveSharp,
    addCircleOutline, linkOutline, mailOutline, refreshCircleOutline, pricetagsOutline, helpCircleOutline, chatboxEllipsesOutline,
    logOutOutline, search, arrowUpCircleOutline, arrowDownCircleOutline, closeCircleOutline  }



  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'EasyIcon',
    displayName: "easy-icon",
    components: {

    },
    setup() { return icons; },
    data: () => ({
    }),
    methods: {    
      getIcon: function(name) {
        switch(name) {
          case "logout":
            return logOutOutline
          case "search":
            return search
          case "promote":
            return arrowUpCircleOutline
          case "demote":
            return arrowDownCircleOutline
          case "delete":
            return closeCircleOutline
          case "refresh":
            return refreshCircleOutline
        }
      },
    },
    props: {
      type: String,
    },
})
</script>