<template>
    <ion-page>
    <ion-content>  
      <ion-card>
        <ion-card-header>
          <ion-card-title>Users</ion-card-title>
        </ion-card-header>

        <ion-card-content v-if="users.length > 0">
            <ion-grid>
                <ion-row class="table-row" v-for="user in users" :key="user.id" >
                    <ion-col>{{ user.display_name }}</ion-col>
                    <ion-col>{{ roleName(user.level) }}</ion-col>
                    <ion-col>
                        <ion-buttons class="ion-justify-content-end">
                            <ion-button size="small" fill="clear" color="success" v-if="canPromoteUser(user.level)" @click="promoteUser(user.id)" alt="Promote User"><EasyIcon type="promote" /> Promote</ion-button>
                            <ion-button size="small" fill="clear" color="warning" v-if="canDemoteUser(user.level)" @click="demoteUser(user.id)"><EasyIcon type="demote" /> Demote</ion-button>
                            <ion-button size="small" fill="clear" color="danger" v-if="canDeleteUser(user.level)" @click="deleteUser(user.id)"><EasyIcon type="delete" /> Delete</ion-button>
                        </ion-buttons>
                    </ion-col>
                </ion-row>
                
            </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
            <ion-card-title>Create User</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <form @submit.prevent="createUser" @keyup.enter="createUser">
            <ion-item>
                <ion-input label="Username" label-placement="floating" v-model="newUsername"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input label="Password" label-placement="floating"  v-model="newPassword"></ion-input>
            </ion-item>
            <ion-button type="submit" fill="outline" color="success">Create</ion-button>
            </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>
<script setup>
    import { inject, ref } from 'vue'
    import { useTitle } from '@vueuse/core'
    import { onIonViewWillEnter } from '@ionic/vue'
    useTitle("The Boss - Users")

    const Api = inject("api")
    const Session = inject("session")

    const users = ref([])

    const userLevels = [0,1,10,20]
    const userLevelNames = ["Disabled", "User", "Admin", "Super Admin"]


    const loadUsers = async () => {
        const action = "list_users"
        const args = {}
        users.value = (await Api.SendMessage(action, args)).users
    }

    const manageUser = async (id, subaction, user, pass) => {
        const action = "manage_user"
        const args = { 
            id: id,
            action: subaction,
            username: user,
            password: pass,
        }
        await Api.SendMessage(action, args)
        loadUsers()
    }
    const deleteUser = async (id) => {
        await manageUser(id, "delete")
    }
    const promoteUser = async (id) => {
        await manageUser(id, "promote")
    }
    const demoteUser = async (id) => {
        await manageUser(id, "demote")
    }


    const newUsername = ref("")
    const newPassword = ref("")
    const createUser = async () => {
        if(newUsername.value == "" || newPassword.value == "") return
        manageUser(0, "create", newUsername.value, newPassword.value)
    }


    const canDemoteUser = (level) => {
        const myLevel = Session._session.level
        const idx = userLevels.indexOf(level)
        if(idx <= 1) return false // Can't demote to disabled
        return myLevel > level
    }
    const canPromoteUser = (level) => {
        const idx = userLevels.indexOf(level)
        if(idx < 0) return false
        if(idx == userLevels.length-1) return false

        const nextLevel = userLevels[idx+1]
        console.log("nextLevel", level, nextLevel, Session._session.level > nextLevel)
        return Session._session.level > nextLevel
    }
    const canDeleteUser = (level) => {
        const myLevel = Session._session.level
        return myLevel > level
    }

    const roleName = (level) => {
        const idx = userLevels.indexOf(level)
        if(idx < 0) return "Unknown"
        return userLevelNames[idx]
    }


    
    onIonViewWillEnter(async () => {
        await loadUsers()
    })
</script>

<style scoped>
.table-header {
    font-weight: bold;
}
.table-row:hover {
    background-color: rgba(0,0,0,0.2);
}
</style>