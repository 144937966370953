import { useWebSocket, useLocalStorage } from '@vueuse/core'
import { ref, watch } from 'vue'
import { reactive } from 'vue'

function newId() {
    return Math.random().toString(36)
}


class TheBossAPI {
    constructor(url, options) {
        this.websocket = useWebSocket(url, options)
        this.event = ref({})
        this.session = ref({})
        this.error = ref({
            message: "",
            timestamp: new Date().getTime()
        })
        this.pending = {}

        watch(this.websocket.data, (data) => {
            if (!data) return
            data.split("\n").forEach((line) => {
                const msg = JSON.parse(line.trim())
                this.resolvePending(msg)
                console.log("msg",msg)
                switch(msg.action) {
                    case "login":
                    case "logout":
                    case "session":
                        if(msg.success) {
                            this.session.value = msg.data
                        }
                        break
                    case "event":
                        this.event.value = msg.data
                }

                if(!msg.success) {
                    this.error.value = {
                        message: msg.data,
                        timestamp: new Date().getTime()
                    }
                }
                
            })
        })

        watch(this.websocket.status, (status) => {
            switch (status) {
                case "CONNECTING":
                    console.log("Connecting")
                    break
                case "OPEN":
                    Session.Refresh();
                    break
                case "CLOSED":
                    console.log("Closed")
                    break
            }
        })

        setInterval(this.checkTimeouts, 60000)
    }

    resolvePending(msg) {
        if (msg.id) {
            if (this.pending[msg.id]) {
                if (msg.success) {
                    this.pending[msg.id].resolve(msg.data)  
                } else {
                    this.pending[msg.id].reject(msg.data)
                }
                delete this.pending[msg.id]
            }
        }
    }

    SendMessage(action, data) {
        const id = newId()
        const created = new Date().getTime()
        console.log("sending", JSON.stringify({id,action,data}))
        const res = this.websocket.send(JSON.stringify({id,action,data}), true)
        if (!res) {
            return Promise.reject("Failed to send")
        } else {
            return new Promise((resolve, reject) => {
                this.pending[id] = {resolve, reject, created}
            })
        }
    }

    Search(type, argument) {
        const action = "search_player"
        const data = {}
        data[type] = argument
        return this.SendMessage(action, data)
    }

    checkTimeouts() {
        const timeout = 10000
        const now = new Date().getTime()
        for (const id in this.pending) {
            if (now - this.pending[id].created > timeout) {
                this.pending[id].reject("Timeout")
                delete this.pending[id]
            }
        }
    }
}

class SessionContainer {
    constructor() {
        this._session = reactive({
            id: "",
            user_id: 0,
            display_name: "",
            level: 0,
        });

        this.id = useLocalStorage("session", "")
    }
    Refresh() {
        this.id = useLocalStorage("session", "")
        if(this.id.value != "") {
            Api.SendMessage("login", {session_id: this.id.value})
        }
    }
}

const wssLocation = window.location.protocol.replace("http", "ws") + "//" + window.location.host + "/wss"
export const Api = new TheBossAPI(wssLocation, {
    autoReconnect: true,
    immediate: true
})
export const Session = new SessionContainer()


watch(Api.session, (session) => {
    for(const key in session) {
        Session._session[key] = session[key]
    }
    Session.id.value = session.id
})
