<template>
    <ion-page>
    <ion-content>
        <ion-card>
            <ion-card-header>
                <ion-card-title>Change Display Name</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-item>
                    <ion-input label-placement="stacked" label="Display Name" type="text" v-model="displayName" :placeholder="Session._session.display_name"></ion-input>
                </ion-item>
                <ion-button @click="updateDisplay" :disabled="displayName == '' || displayName == Session._session.display_name">Update Display Name</ion-button>
            </ion-card-content>
        </ion-card>

        <ion-card>
            <ion-card-header>
                <ion-card-title>Change Password</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-item>
                    <ion-input label-placement="floating" label="Original Password" type="password" v-model="originalPassword"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="floating" label="New Password" type="password" v-model="newPassword"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="floating" label="Confirm Password" type="password" v-model="confirmPassword"></ion-input>
                </ion-item>
                <ion-button @click="updatePassword" :disabled="!(newPassword == confirmPassword && confirmPassword != '')">Update Password</ion-button>
            </ion-card-content>
        </ion-card>
    </ion-content>
    </ion-page>
</template>

<script setup>
import { inject, ref } from 'vue'

const Api = inject("api")
const Session = inject("session")

const displayName = ref("")
const originalPassword = ref("")
const newPassword = ref("")
const confirmPassword = ref("")

const updatePassword = function() {
  const action = "change_password"
  const data = {
    old_password: originalPassword.value,
    new_password: newPassword.value,
    user_id: Session._session.user_id
  }

  Api.SendMessage(action, data).then(() => {
    originalPassword.value = ""
    newPassword.value = ""
    confirmPassword.value = ""
  })
}

const updateDisplay = function() {
    const action = "change_display"
    const args = {
        display_name: displayName.value,
        user_id: Session._session.user_id
    }
    console.log("updateDisplay", args)
    Api.SendMessage(action, args).then(()=>{
        displayName.value = ""   
    })
}

</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>