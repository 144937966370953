<template>
    <ion-page>
    <ion-content>  
      <ion-card>
        <ion-card-header>
          <ion-card-title>Login</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <form @submit.prevent="login" @keyup.enter.prevent="login">
            <ion-item>
              <ion-input label="Username" label-placement="stacked" v-model="username"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input label="Password" label-placement="stacked" type="password" v-model="password"></ion-input>
            </ion-item>
            
              <ion-button type="submit" color="success" fill="clear">Submit</ion-button>
            
          </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useTitle } from '@vueuse/core'
import { onIonViewWillEnter } from '@ionic/vue'

const Api = inject("api")

const username = ref("")
const password = ref("")

const login = function() {
  const action = "login"
  const data = {
    username: username.value,
    password: password.value
  }
  console.log(action, data)
  Api.SendMessage(action, data)
}

onIonViewWillEnter(() => {
  useTitle("The Boss - Login")
})
</script>

<style scoped>
ion-card {
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>