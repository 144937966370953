<template>
  <ion-page>
    <ion-content v-if="!hadError && info.player.user_id > 0">
      <ion-card>
        <ion-card-header>
          <ion-card-title>{{ info.player.display_name }} ({{ info.player.username }})</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-grid>
            <ion-row>
              <ion-col>Account State</ion-col>
              <ion-col>{{accountStateText}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col>Last Login</ion-col>
              <ion-col>{{ prettyDate(info.player.last_login) }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col>Remote Address</ion-col>
              <ion-col>{{ info.connection.remote_addr }}:{{ info.connection.remote_port }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col>Local Address</ion-col>
              <ion-col>{{ info.connection.local_addr }}:{{ info.connection.local_port }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col>Has Emblem</ion-col>
              <ion-col>{{ hasEmblem }}</ion-col>
            </ion-row>
            <ion-row v-if="hasEmblem">
              <ion-col>Emblem Text</ion-col>
              <ion-col>{{ info.player.emblem_text }}</ion-col>
            </ion-row>
          </ion-grid>          
        </ion-card-content>
        <ion-footer>
          <ion-row>
            <ion-col>
              <ion-buttons class="ion-justify-content-start">
                <ion-button color="success" v-if="!hasEmblem" @click="updateEmblem(true, info.player.emblem_text)">Give Emblem</ion-button>
                <ion-button color="danger" v-else @click="updateEmblem(false, info.player.emblem_text)">Remove Emblem</ion-button>                
              </ion-buttons>
            </ion-col>
            <ion-col>
              <ion-buttons class="ion-justify-content-end">
                <ion-button color="warning" v-if="isStateSoftBanned" @click="changeState('')">Remove Softban</ion-button>
                <ion-button color="warning" v-else @click="changeState('SOFTBANNED')">Softban</ion-button>

                <ion-button color="danger" v-if="isStateBanned" @click="changeState('')">Remove Ban</ion-button>
                <ion-button color="danger" v-else @click="changeState('BANNED')">Ban</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-footer>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Change Password</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-item>
            <ion-input label="New Password" label-placement="floating" type="password" v-model="newPassword"></ion-input>
          </ion-item>
        </ion-card-content>
        <ion-footer>
          <ion-row>
            <ion-col>
              <ion-buttons class="ion-justify-content-start">
                <ion-button color="success" @click="changePassword">Update</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-footer>
      </ion-card>
      <ion-card v-if="hasEmblem">
        <ion-card-header>
          <ion-card-title>Change Emblem Text</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-item>
            <ion-input label="New Emblem Text" label-placement="floating" v-model="emblemText"></ion-input>
          </ion-item>
        </ion-card-content>
        <ion-footer>
          <ion-row>
            <ion-col>
              <ion-buttons class="ion-justify-content-start">
                <ion-button color="success" @click="updateEmblem(hasEmblem, emblemText)">Update</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-footer>
      </ion-card>
    </ion-content>
    <ion-content v-else class="error">
        Unable to find player.
    </ion-content>
  </ion-page>
</template>
<script setup>

import { inject, defineProps, ref, computed } from 'vue'
import { useTitle } from '@vueuse/core'
import { onIonViewWillEnter } from '@ionic/vue'
useTitle("The Boss - Player Profile")
const Api = inject("api")
const info = ref({
  player: {
    user_id: 0
  }
})
const hadError = ref(false)

const props = defineProps({
  user_id: String
})

const prettyDate = (date) => {
    if(date == 0) return "Never"
    date = date * 1000
    return new Date(date).toLocaleString()
}

const hasEmblem = computed(() => {
  return (info.value.player.flags & 0x10000) == 0x10000
})

const isStateBanned = computed(() => {
  return info.value.player.account_state == "BANNED"
})
const isStateSoftBanned = computed(() => {
  return info.value.player.account_state == "SOFTBANNED"
})

const accountStateText = computed(() => {
  switch(info.value.player.account_state) {
    case "":
      return "Normal"
    case "BANNED":
      return "Banned"
    case "SOFTBANNED":
      return "Soft Banned"
    default:
      return `Unknown: ${info.value.player.account_state}`
  }
})

const loadProfile = async () => {
  try {
    const data = await  Api.SendMessage("player_profile", { user_id: Number(props.user_id) })
    info.value = data
    emblemText.value = data.player.emblem_text
    hadError.value = false
  } catch(err) {
    hadError.value = true
  }
}

const emblemText = ref("")

const updateEmblem = async (emblemState, text) => {
  const action = "update_emblem"
  const data = {
    user_id: Number(props.user_id),
    emblem_state: emblemState,
    emblem_text: text
  }
  await Api.SendMessage(action, data)
  notifySuccess("emblem updated")
  loadProfile()
}


const newPassword = ref("")
const changePassword = async () => {
  const action = "change_mgo_password"
  const data = {
    user_id: Number(props.user_id),
    password: newPassword.value
  }
  await Api.SendMessage(action, data)
  newPassword.value = ""
  notifySuccess("password changed")
  loadProfile()
}

const changeState = async (newState) => {
  const action = "update_mgo_state"
  const data = {
    user_id: Number(props.user_id),
    account_state: newState
  }
  await Api.SendMessage(action, data)
  notifySuccess("account state changed")
  loadProfile()
}


import { toastController } from '@ionic/vue'
const notifySuccess = (message) => {
  toastController.create({
    message: message,
    duration: 5000,
    color: "success",
    position: "top"
  }).then(toast => toast.present())
}


onIonViewWillEnter(async () => {
  await loadProfile()
  useTitle(`The Boss - ${info.value.player.username}`)
})
</script>

<style scoped>
    .error {
        text-align: center;
        font-size: 2em;
        padding: 1em;
    }
</style>