import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import UpdateProfilePage from '../views/UpdateProfilePage.vue'
import PlayerSearchPage from '../views/PlayerSearchPage.vue'
import PlayerProfilePage from '../views/PlayerProfilePage.vue'
import AdminUsersPage from '../views/AdminUsersPage.vue'
import PlayerBannedPage from '../views/PlayerBannedPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/player/search'
  },
  {
    path: '/update_profile',
    name: 'UpdateProfile',
    component: UpdateProfilePage,
  },
  {
    path: '/player/search',
    name: 'PlayerSearch',
    component: PlayerSearchPage,
  },
  {
    path: '/player/banned',
    name: 'PlayerBanned',
    component: PlayerBannedPage,
  },
  {
    path: '/player/:user_id',
    name: 'PlayerProfile',
    component: PlayerProfilePage,    
    props: true,
  },
  {
    path: '/admin/users',
    name: 'AdminUsersPage',
    component: AdminUsersPage,    
    props: true,
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
