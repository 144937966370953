<template>
    <ion-page>
    <ion-content>  
      <ion-card>
        <ion-card-header>
          <ion-card-title>Banned Players</ion-card-title>
        </ion-card-header>

        <ion-card-content>
            <ion-grid v-if="results && results.length > 0">
                <ion-row class="table-header">
                    <ion-col size="1">User ID</ion-col>
                    <ion-col>Name</ion-col>
                    <ion-col>State</ion-col>
                    <ion-col>Last Login</ion-col>
                </ion-row>
                <ion-row class="table-row" v-for="result in results" :key="result.id" >
                    <ion-col size="1"><a :href="'#/player/' + result.user_id">{{ result.user_id }}</a></ion-col>
                    <ion-col>{{ result.display_name }} ({{ result.username }})</ion-col>
                    <ion-col>{{ result.account_state }}</ion-col>
                    <ion-col>{{ prettyDate(result.last_login) }}</ion-col>
                </ion-row>
            </ion-grid>
            <em v-else>No Banned Users</em>
        </ion-card-content>
      </ion-card>

    </ion-content>
  </ion-page>
</template>
<script setup>
    import { inject, ref} from 'vue'
    import { useTitle } from '@vueuse/core'
    import { onIonViewWillEnter } from '@ionic/vue'
    const results = ref([])    
    const Api = inject("api")

    const loadBannedPlayers = async () => {
        const action = "search_player"
        const args = {
            state: "%BAN%"
        }
        
        try {
            results.value = (await Api.SendMessage(action, args)).players
        } catch (e) {
            results.value = []
        }
    }


    const prettyDate = (date) => {
        if(date == 0) return "Never"
        date = date * 1000
        return new Date(date).toLocaleString()
    }


    onIonViewWillEnter(async () => {
        await loadBannedPlayers()
        useTitle("The Boss - Banned Players")
    })
</script>

<style scoped>
.table-header {
    font-weight: bold;
}
.table-row:hover {
    background-color: rgba(0,0,0,0.2);
}
</style>