<template>
    <ion-page>
    <ion-content>  
      <ion-card>
        <ion-card-header>
          <ion-card-title>Player Search</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <form @submit.prevent="searchPlayer">
            <ion-item>
              <ion-input label="Player Name" label-placement="floating" v-model="inputs.player.value"></ion-input>
            </ion-item>            
            <ion-button type="submit" :disabled="isPlayerSearchDisabled" color="success" fill="clear"><EasyIcon class="ion-padding-end" type="search"></EasyIcon> Search</ion-button>
          </form>
          <ion-grid v-if="hasPlayerResults">
            <ion-row class="table-header">
                <ion-col size="1">User ID</ion-col>
                <ion-col>Name</ion-col>
                <ion-col>State</ion-col>
                <ion-col>Last Login</ion-col>
            </ion-row>
            <ion-row class="table-row" v-for="result in playerResults" :key="result.asdas" >
              <ion-col size="1"><a :href="'#/player/' + result.user_id">{{ result.user_id }}</a></ion-col>
              <ion-col>{{ result.display_name }} ({{ result.username }})</ion-col>
              <ion-col>{{ result.account_state }}</ion-col>
              <ion-col>{{prettyDate(result.last_login) }}</ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-card-title>IP Search</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <form @submit.prevent="searchIp">
            <ion-item>
              <ion-input label="IP" label-placement="floating" v-model="inputs.ip.value"></ion-input>
            </ion-item>            
            <ion-button type="submit" :disabled="isIpSearchDisabled" color="success" fill="clear"><EasyIcon class="ion-padding-end" type="search"></EasyIcon> Search</ion-button>
          </form>
          <ion-grid v-if="hasIpResults">
                <ion-row class="table-header">
                  <ion-col size="1">User ID</ion-col>
                  <ion-col>Name</ion-col>
                  <ion-col>Remote Address</ion-col>
                  <ion-col>Local Address</ion-col>
                </ion-row>
                <ion-row class="table-row" v-for="result in ipResults" :key="result.player.display_name" >
                    <ion-col size="1"><a :href="'#/player/' + result.user_id">{{ result.user_id }}</a></ion-col>
                    <ion-col          v-if="result.player">{{ result.player.display_name }} ({{ result.player.username }})</ion-col>
                    <ion-col          v-else>[deleted]</ion-col>
                    <ion-col :class="result.matched_remote?'matched_addr':''">{{ result.remote_addr }}:{{ result.remote_port }}</ion-col>
                    <ion-col :class="result.matched_local?'matched_addr':''">{{ result.local_addr }}:{{ result.local_port }}</ion-col>
                </ion-row>
            </ion-grid>
        </ion-card-content>
      </ion-card>

    </ion-content>
  </ion-page>
</template>
<script setup>
    import { inject, ref, computed } from 'vue'
    import { useTitle } from '@vueuse/core'
    import { onIonViewWillEnter } from '@ionic/vue'
    useTitle("The Boss - Player Search")

    const Api = inject("api")

    const results = {
      "players": ref([]),
      "ips": ref({
        "connections": [],
        "players": []
      })
    }

    const disabled = {
      "players": ref(false),
      "ips": ref(false)
    }

    const inputs = {
      "player": ref(""),
      "ip": ref("")
    }


    const hasPlayerResults = computed(() => results.players.value.length > 0)
    const hasIpResults = computed(() => results.ips.value.connections.length > 0)

    const isPlayerSearchDisabled = computed(() => disabled.players.value)
    const isIpSearchDisabled = computed(() => disabled.ips.value)

    const searchPlayer = async () => {
      const argument = inputs.player.value
      disabled.players.value = true
      try {
        const res = await Api.Search("player", argument)
        if (res.players === null) {
          res.players = []
          notify("No results found", "warning")
        }
        results.players.value = res.players
      } finally {
        disabled.players.value = false
      }
    }

    const searchIp = async () => {
      const argument = inputs.ip.value
      disabled.ips.value = true
      try {
        const res = await Api.Search("ip", argument)
        if (res.connections === null) {
          res.connections = []
          notify("No results found", "warning")
        }
        if (res.players === null) {
          res.players = []
        }
        results.ips.value = res
      } finally {
        disabled.ips.value = false
      }
    }


    const ipResults = computed(() => {
      if(!hasIpResults.value) return []
      const conns = results.ips.value.connections
      const players = results.ips.value.players

      const playerMap = {}
      for(const player of players) {
        playerMap[player.user_id] = player
      }
      for (const conn of conns) {
        conn.player = playerMap[conn.user_id]
        conn.matched_remote = (conn.remote_addr.indexOf(inputs.ip.value) == 0)
        conn.matched_local = (conn.local_addr.indexOf(inputs.ip.value) == 0)
        
      }
      return conns
    })

    const playerResults = computed(() => {
      if(!hasPlayerResults.value) return []
      const players = results.players.value
      return players
    })

    const prettyDate = (date) => {
        if(date == 0) return "Never"
        date = date * 1000
        return new Date(date).toLocaleString()
    }

  import { toastController } from '@ionic/vue'
  const notify = (message, color="success") => {
    toastController.create({
      message: message,
      duration: 5000,
      color: color,
      position: "top"
    }).then(toast => toast.present())
  }

    onIonViewWillEnter(async () => {
      useTitle("The Boss - Player Search")
    })

</script>

<style scoped>
.table-header {
    font-weight: bold;
}
.table-row:hover {
    background-color: rgba(0,0,0,0.2);
}
.table-row a {
  text-decoration: none;
}

.matched_addr {
    font-weight: bold;
    text-decoration:underline;
}
</style>