<template>
  <ion-app>
    <ion-menu content-id="main">
      <ion-header>
        <ion-toolbar color="tertiary">
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding" v-if="isLoggedIn">
        
        <ion-item-divider>Player Management</ion-item-divider>
        <ion-item href="#/player/search">
          <ion-label>Search for Player</ion-label>
        </ion-item>
        <ion-item href="#/player/banned">
          <ion-label>Ban List</ion-label>
        </ion-item>

        <ion-item-group v-if="Session._session.level >= 10">
          <ion-item-divider>Admin</ion-item-divider>
          <ion-item href="#/admin/users">
            <ion-label>Users</ion-label>
          </ion-item>
        </ion-item-group>
       
        
        <ion-item-divider>Your Account</ion-item-divider>
        <ion-item href="#/update_profile">
          <ion-label>Update Account</ion-label>
        </ion-item>
        <ion-item button @click="logout">
          <ion-label>Logout</ion-label>
          <EasyIcon type="logout"/>
        </ion-item>
        
          
        
      </ion-content>
    </ion-menu>
    <ion-page>
      <ion-header collapse="fade">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>{{title}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content id="main">
      <LoginPage id="main"  v-if="isLoggedIn !== true"/>
      <ion-router-outlet v-else/>
      </ion-content>
    </ion-page>
  </ion-app>
</template>

<script setup>
import { inject, watch, computed } from 'vue'
import { useTitle } from '@vueuse/core'
import LoginPage from './views/LoginPage.vue'

const Api = inject("api")
const Session = inject("session")
const isLoggedIn = computed(() => {
    return Session._session.user_id > 0
})

const logout = function() {
  const action = "logout"
  const data = {}
  console.log(action, data)
  Api.SendMessage(action, data)
}


import { toastController } from '@ionic/vue';

watch(Api.error, async (error) => {
  const toast = await toastController.create({
    message: 'Error: ' + error.message,
    duration: 3000,
    color: "danger",
    position: "top",
  });
  return toast.present();
})

useTitle("The Boss - Home")
</script>


<style>
ion-split-pane {
  --side-max-width: 300px;
}

</style>