import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router);

import { Api, Session } from './api.js'
app.provide("api", Api);
app.provide("session", Session);

import { IonApp, IonRouterOutlet, IonContent, IonPage, IonCard, IonHeader, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/vue';
app.component('ion-app', IonApp)
app.component('ion-router-outlet', IonRouterOutlet)
app.component('ion-content', IonContent)
app.component('ion-page', IonPage)
app.component('ion-card', IonCard)
app.component('ion-header', IonHeader)
app.component('ion-card-header', IonCardHeader)
app.component('ion-card-title', IonCardTitle)
app.component('ion-card-subtitle', IonCardSubtitle)
app.component('ion-card-content', IonCardContent)

import { IonInput, IonButton, IonGrid, IonRow, IonCol, IonItem, IonTitle, IonToolbar, IonButtons } from '@ionic/vue';
app.component('ion-input', IonInput)
app.component('ion-button', IonButton)
app.component('ion-grid', IonGrid)
app.component('ion-row', IonRow)
app.component('ion-col', IonCol)
app.component('ion-item', IonItem)
app.component('ion-title', IonTitle)
app.component('ion-toolbar', IonToolbar)
app.component('ion-buttons', IonButtons)

import { IonSplitPane, IonMenu, IonMenuButton, IonMenuToggle, IonList, IonItemSliding, IonItemOptions, IonItemOption, IonLabel, IonIcon } from '@ionic/vue';
app.component('ion-split-pane', IonSplitPane)
app.component('ion-menu', IonMenu)
app.component('ion-menu-button', IonMenuButton)
app.component('ion-menu-toggle', IonMenuToggle)
app.component('ion-list', IonList)
app.component('ion-item-sliding', IonItemSliding)
app.component('ion-item-options', IonItemOptions)
app.component('ion-item-option', IonItemOption)
app.component('ion-label', IonLabel)
app.component('ion-icon', IonIcon)

import { IonItemDivider, IonFooter, IonItemGroup } from '@ionic/vue';
app.component('ion-item-divider', IonItemDivider)
app.component('ion-footer', IonFooter)
app.component('ion-item-group', IonItemGroup)

import EasyIcon from './components/EasyIcon.vue'
app.component('EasyIcon', EasyIcon)



router.isReady().then(() => {
  app.mount('#app');
});